import {
    useContext, useCallback, useState, useMemo
} from 'react';
import { UserProfileContext } from './getContext';
import { usePusherChannel } from './pusher.utills';

export const UseQuickNotifications = () => {
    const [quickNotifications, setQuickNotifications] = useState([]);
    const [quickNotificationsForCounter, setQuickNotificationsForCounter] = useState([]);

    const { profileData } = useContext(UserProfileContext) || {};

    const onMessage = useCallback((data) => {
        setQuickNotifications((pre) => [...pre, data]);
        setQuickNotificationsForCounter((pre) => [...pre, data]);
    }, []);
    const events = useMemo(() => ['Illuminate\\Notifications\\Events\\BroadcastNotificationCreated'], []);
    usePusherChannel(profileData?.hasOwnProperty('id') && `private-App.Models.User.${profileData?.id}`, events, { onMessage });
    return {
        quickNotifications, quickNotificationsForCounter, setQuickNotificationsForCounter
    };
};